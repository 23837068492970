// The Main Site Object
window.Site = {

    // We need to store the colours
    button      : document.querySelector('.menu-button'),
    menu        : document.querySelector('.menu'),
    menuItem    : document.querySelector('.menu-item:first-child'),
    menuActive  : document.querySelector('.menu-item-active'),

    // Stories Toggle
    followBtn  : document.querySelector('.follow-btn'),
    followCont : document.querySelector('#follow'),

    // Overlay Functionality
    overlayContainer : document.querySelector('.overlay'),
    overlayClose     : document.querySelector('.overlay-close'),

    // Init Function
    liftoff : () => {

        Site.showHideMenu();
        Site.onClickCloseTheMenu();
        Site.onClickShowHideFollowSection();

        Site.Overlay.init();
        Site.Scrolltop.toggleButton()
        Site.Scrolltop.scroll()

        Site.showVideosTiedToImages()

    },

    // Show Hide the Info Section
    showHideMenu : () => {

        if (!Site.menu) return;

        Site.button.addEventListener('click' , () => {
            Site.button.innerHTML = (Site.button.innerHTML == 'MENU') ? "CLOSE" : "MENU"
            Site.menu.classList.toggle('menu-open')
            document.body.classList.toggle('menu-is-open')
        })
    },

    // On click close the menu if it's open
    onClickCloseTheMenu : () => {

        Site.menuItem.addEventListener('click' , () => {

            // if the menu was already close do nothing
            if (!document.body.classList.contains('menu-is-open')) return;

            Site.button.innerHTML = "MENU";
            Site.menu.classList.remove('menu-open')
            document.body.classList.remove('menu-is-open')
        
        })

    },

    onClickShowHideFollowSection : () => {

        if (!Site.followBtn || !Site.followCont) return;

        Site.followBtn.addEventListener('click' , () => {
            Site.followCont.classList.toggle('is-open')

            if (Site.menuActive)
                Site.menuActive.classList.toggle('menu-item-active')

            Site.followBtn.classList.toggle('menu-item-active')

        })

        Site.followCont.addEventListener('click' , (event) => {
            if (event.target == Site.followCont)
                Site.followCont.classList.toggle('is-open')

            if (Site.menuActive)
                Site.menuActive.classList.toggle('menu-item-active')

            Site.followBtn.classList.toggle('menu-item-active')
        })

    },

    Overlay : {

        // Set the storage name
        storageKey : () => {

            // Instantiate a new date 
            const date = new Date(); 

            // format the day to match today
            let day   = date.getDate();
            let month = date.getMonth() + 1;
            let year  = date.getFullYear();

            // Create the local storage string
            let storageKey = `overlay-${year}-${month}-${day}`;

            return storageKey;
        },

        // We need to do a couple of things for this to work properly
        init : () => {

            // Grab the storage key
            let storage = Site.Overlay.storageKey();

            // Check if there's data in the session storage
            let session = localStorage.getItem(storage);

            // If there's something then there's no need to do anything
            if (session != null) { return }

            // If it's null we need to do a few things
            localStorage.clear()
            Site.Overlay.display()
            Site.Overlay.whenOpenPreventScroll()
            Site.Overlay.removeOverlayOnClick()


        },


        // Prevent body scroll when the overlay is open
        whenOpenPreventScroll : () => {
            if (Site.overlayContainer) {
                document.body.style.overflow = "hidden"
            }
        },

        display : () => {
            if (Site.overlayContainer) {
                Site.overlayContainer.classList.remove('overlay-hidden')

                // Grab the storage key
                let storage = Site.Overlay.storageKey();

                // Set the session storage content
                localStorage.setItem(storage, "displayed");
            }
        },

        // Delete overlay On Click
        removeOverlayOnClick : () => {

            if (!Site.overlayContainer) return;
            
            Site.overlayClose.addEventListener('click' , () => {
                Site.overlayContainer.remove()
                document.body.style.overflow = null
            })

        }

    },

    // Scroll top button and functionality
    Scrolltop : {

        // Grab the scrolltop button
        button       : document.getElementById('scroll-top'),
        pastposition : 0,
        direction    : 'down',

        // Show and Hide the button on scroll
        toggleButton : () => {

            if (!Site.Scrolltop.button) return;

            window.addEventListener('scroll' , () => {

                // First we determine the direction
                Site.Scrolltop.direction = (Site.Scrolltop.pastposition < window.scrollY) ? "down" : "up";

                // Now we have a direction and we can perform a few checks
                if (window.scrollY < 150 &&
                    Site.Scrolltop.direction == "up" &&
                    Site.Scrolltop.button.classList.contains('is-visible')) {
                        Site.Scrolltop.button.classList.remove('is-visible')
                    }

                if (window.scrollY > 150 &&
                    Site.Scrolltop.direction == "down" &&
                    !Site.Scrolltop.button.classList.contains('is-visible')) {
                        Site.Scrolltop.button.classList.add('is-visible')
                    }

                Site.Scrolltop.pastposition = window.scrollY;

        
            })
        },

        // Scroll window
        scroll : () => {

            if (!Site.Scrolltop.button) return;

            Site.Scrolltop.button.addEventListener('click' , () => {

                console.log('YOLO')

                window.scrollBy({
                    top      : window.scrollY * -1,
                    left     : 0,
                    behavior : "smooth",
                });
            })
        }

    },

    showVideosTiedToImages : () => {

        let pictures = document.querySelectorAll('.picture-with-video');
        if (!pictures) return;

        pictures.forEach(picture => {
            picture.addEventListener('click' , () => {

                picture.classList.add('picture-with-video-after-click')

            })
        })

    }



}

// Launch The Script
Site.liftoff()
